.container-fluid {
  padding-left: 0px !important;
  padding-right: 0PX !important;
}

.row {
  padding-left: 0px !important;
  padding-right: 0PX !important;
}

.LoginCol {
  padding-left: 0px !important;
  padding-right: 0pX !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.LoginCol2 {
  padding-left: 0px !important;
  padding-right: 0pX !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.LoginRow {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px !important;
  margin-right: 0pX !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.LoginRow img {
  width: 100%;
  height: 100vh;
}

.LoginImage {
  padding-top: 20px;
  height: 100%;
  margin: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginImage img {
  max-width: 300px;
  min-width: 400px;
  height: 200px;
  margin: 0px !important;
}

.LoginImage .form-label {
  color: #0a0a0a !important;
}

.LogoWhite {
  display: none;
}

@media (max-width:600px) {
  .LogoBlack {
    display: none;
  }

  .LoginImage img {
    max-width: 290px;
    min-width: 400px;
    height: 200px;
    margin: 0px !important;
  }

  .LogoWhite {
    display: block;
  }

  .LoginSmallbg {
    background-image: url('../assets/LoginBgImg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  .LoginImage {
    background-color: none !important;
    background: rgba(0, 0, 0, 0.871), url('../assets/LoginBgImg.jpg') !important;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    height: 0vh;
    min-height: 100vh;
  }

  .LoginCol img {
    display: none;
  }

  .LoginImage .form-label {
    color: #FFFFFF !important;
  }
}

/* .form-label{
    color:#0a0a0a !important;
} */

.tickettable input {
  margin: 0px !important;
}

.tickettable table {
  background-color: rgb(165, 127, 255);
  padding: 5px 5px;
  border-radius: 10px;
}

.tickettable thead {
  border-color: rgb(179, 130, 238);
}

.tableBody tr {
  margin: 10px 0px !important;
}

.ticketFaceMain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFFBF5;
  padding: 10px 20px;
  gap: 5px;
}
.ticketFaceid{
  width: 100%;
  border: 1px dashed #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px 0px;
}
.ticketQr {
  max-width: 100%;
}
.ticketLogo {
  width: 100%;
}
.ticketFace {
  width: 70%;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(165, 127, 255);
}

.ticketFaceDetail {
  width: 100%;
  display: flex;
  justify-content: space-between;
}





.ticketNoteText {
  font-size: 10px;
}

@media (max-width:600px) {
  .ticketFace {
    width: 100%;
    margin: 5px;
    padding: 5px;
    background-color: rgb(165, 127, 255);
  }
}

.TicketCheck .form-check-input {
  --bs-form-check-bg: rgb(255, 255, 255);
  border: 2px solid #353434;
}

.error {
  color: #F35421;
}

.ticketSelect {
  padding: 0px 0px !important;

}

.ticketSelect select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

.ticketinput {
  padding: 0px 0px !important;
}

.profile .dropdown ul {
  position: absolute;
  inset: 13px 0px auto -20px !important;
}

.dropdownOption {
  color: #0a0a0a !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.newTicket .nav-tabs .nav-link.active {
  background-color: transparent !important;
  border-color: transparent !important;
}

.newTicket .nav-tabs {
  --bs-nav-tabs-border-width: none !important;
}

.assignList {
  min-height: 50vh;
  max-height: auto;
}

/* .assignList .css-3w2yfm-ValueContainer{
    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
    
} */

/* @media (max-width:500px) {
    .offcanvas.offcanvas-end{
        width:var(--bs-offcanvas-width);
    }
} */
/* .filterIcon{
    height: 40px;
} */

.SelectDesign select {
  margin: 0px 0px !important;
  padding: 10px 10px !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  border: 1px solid #1EA7FD50;
  min-width: 150px;
}

.SelectDesign>input[type='date'] {
  margin: 0px 0px !important;
  padding: 10px 10px !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  border: 1px solid #1EA7FD50;
  min-width: 150px;
}

.imageBg {
  padding: 10px;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.imageBtn {
  display: flex;
  flex-direction: column;
}

.Imgbtn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
  cursor: pointer;
}

.ImgSponsorBtn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  margin-left: -180px;
  opacity: 0;
  cursor: pointer;
}

.Sponsoruploadbtn {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.Sponsoruploadbtn input[type=file] {
  font-size: 20px;
  position: absolute;
  opacity: 0;
}

/* .eventRadio input[type=radio]{
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
  .eventRadio {
    height: 20px;
    display: flex;
  } */

/* .eventRadio label{
    margin-bottom: 0px !important;
  } */
/* .eventRadio {
    height: 20px;
    display: flex;
    position: relative;
  } */
.radioGroup input[type=radio] {
  /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0px;
    outline: none;
    vertical-align: middle;
    position: absolute;
    z-index: -1;
    margin: 0px 6px; */
  /* appearance: none;
   border: 1px solid #d3d3d3;
   width: 30px;
   height: 30px;
   content: none;
   outline: none;
   margin: 0; */

}

.radioGroup input[type="radio"] {
  appearance: none;
  margin: 0px 6px;
  appearance: none;
  outline: none;
  padding: 0;
  content: none;
  border: none;
}

.eventRadio1,
.eventRadio2,
.eventRadio3 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
  transition: all 0.3s ease;
  padding: 10px 10px;
  color: #FFFFFF;
}

.eventRadioBlue {
  background-color: #354C8C;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
  transition: all 0.3s ease;
  padding: 10px 10px;
  color: #FFFFFF;
}


.eventRadio1 label {
  background-color: #FFCF52;
  box-shadow: 5px 5px 0px #000;

}

.eventRadio2 label {
  background-color: #46BCAA;
  box-shadow: 5px 5px 0px #000;
}

.eventRadio3 label {
  background-color: #f35421;
  box-shadow: 5px 5px 0px #000;

}

.eventRadio1 input[type="radio"]:checked::before {
  background-color: #ffffff;
  content: "\00A0\2713\00A0" !important;
  font-weight: bolder;
}

.eventRadio2 input[type="radio"]:checked::before {
  background-color: #ffffff;
  content: "\00A0\2713\00A0" !important;
  font-weight: bolder;
}

.eventRadio3 input[type="radio"]:checked::before {
  background-color: #ffffff;
  content: "\00A0\2713\00A0" !important;
  font-weight: bolder;
}

.radioGroup .colorWhite {
  color: #FFFFFF;
  z-index: 99;
  margin-left: -5px !important;
  padding: 5px 5px;
}

.radioGroup .colorBlack {
  color: #6c757d;
  z-index: 1;
}

.bannerBgImageMain {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.bannerBgImageMain img {
  width: 100%;
  /* object-fit: cover; */
  /* object-position: 50% 20%; */
}

.black {
  text-align: center;
  background: #000;
  background-blend-mode: multiply;
  color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: .4;
}

.bannerBgoverlay {
  color: #fff;
  font-size: 1em;
  align-self: center;
  position: absolute;
  z-index: 10;
}

.jodit-status-bar {
  display: none !important;
}

.heightCol {
  min-height: 200px;
  margin-top: 10px;
}

.heightColcard {
  min-height: 300px;
}

.settingDesign {
  margin-right: 20px;
}

.purchasemain::-webkit-scrollbar {
  display: none;
}

.purchasemain {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.purchaseTable {
  width: 100% !important;
  padding: 0px !important;
}

.purchaseTable thead th {
  position: sticky !important;
  top: 0px !important;
  background-color: #FFFFFF;
}

.purchaseTable::-webkit-scrollbar {
  height: 8px;
  background: white;
  cursor: pointer;
}

/* .purchaseTable {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  } */
.purchaseTable th.purchaseTableth,
td.purchaseTabletd {
  min-width: 150px;
}

.purchaseFilter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.purchaseFilterRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
}

.purchaseFilterRow2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 15px;
  flex-direction: column;

}

@media (max-width:990px) {
  .purchaseFilter {
    justify-content: space-evenly;
  }
}

@media (max-width:600px) {
  .purchaseFilterRow {
    flex-wrap: wrap;
  }
}

.purchaseLoadingTable button {
  width: 200px !important;
  padding: 5px 0px !important;
}

.TopTicketSale::-webkit-scrollbar {
  display: none;
}

.fbLogin {
  background: #4267b2;
  border-radius: 5px;
  color: white;
  height: 40px;
  text-align: center;
  width: 250px;
  border: none;
  outline: none;
}

.googlebtn {
  transition: background-color .3s, box-shadow .3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}

.googlebtn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.googlebtn:active {
  background-color: #eeeeee;
}

.googlebtn:focus {
  outline: none;
  box-shadow:
    0 -1px 0 rgba(0, 0, 0, .04),
    0 2px 4px rgba(0, 0, 0, .25),
    0 0 0 3px #c8dafc;
}

.socialImg img {
  width: 40px;
  height: 40px;
  padding: 0px 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.eventImageUrl {
  max-width: 50%;
  max-height: 40%;
}

.loginBtn {
  /* box-sizing: border-box; */
  /* position: relative; */
  /* width: 13em;  - apply for fixed size */
  border: none;
  text-align: center;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  /* color: #FFFFFF; */
  width: 100%;
  /* background: #5B7BD5; */
  outline: none;
  background-color: transparent;
}

/* .loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
} */

/* .loginBtn:focus {
  outline: none;
} */

/* .loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
  } */


/* Facebook */
.loginBtn--facebook {
  /* background-color: #4C69BA; */
  /* background-image: linear-gradient(#4C69BA, #3B55A0); */
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  /* text-shadow: 0 -1px 0 #354C8C; */
}

/* .loginBtn--facebook:before {
    border-right: #364e92 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
  }
  .loginBtn--facebook:after {
    border-left: #364e92 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
  } */

/* .loginBtn--facebook:hover,
  .loginBtn--facebook:focus {
    background-color: #5B7BD5;
    background-image: linear-gradient(#5B7BD5, #4864B1);
  } */
.cardLiveImageList {
  padding: 0px 0px !important;
}

.cardchooseNewImg {
  margin-left: -20px;
}

.cancelImageBtn {
  margin-top: -10px !important;
  margin-left: -10px !important;
  cursor: pointer !important;
}

.cancelImageBtnEvent {
  margin-top: -15px !important;
  margin-left: -15px !important;
  cursor: pointer !important;
  z-index: 999;
}

.cancelImageBtnAbout {
  margin-top: -15px !important;
  margin-left: -25px !important;
  cursor: pointer !important;
  z-index: 999;
}

.popover-string {
  text-decoration: none !important;
}

.swal2-html-container {
  font-size: 18px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
}

.device-emulator-container .black {
  opacity: inherit !important;
}

.filterSelect {
  max-width: 200px !important;
}

.p-datepicker table td>span {
  width: 1.2rem;
  height: 1.2rem;
}
.p-datepicker-calendar{
  width: 200px !important;
}
/* table th,
tbody {
  width: 50px !important;
  height: 50px !important;
}
table tr,
td {
  width: 50px !important;
}
table th, span{
  text-align: center !important;
}
tr{
  width: 10rem !important;
}
.purchaseTable th, td{

} */
.p-inputtext{
  border: 1px solid #1EA7FD50 !important;
}
.p-multiselect{
  border:1px solid  #1EA7FD50 !important;
}
.activebutton{
  cursor: pointer !important;
}